<!-- 信息设置 -->
<template>
  <div>
    <blockTitle title="信息设置">
      <template slot="customContent">
        <el-button
            size="small"
            long
            class="ml-4 bg-blue-900 text-gray-50 w-28"
            @click="submit"
        >
          保存提交
        </el-button>
      </template>
    </blockTitle>
    <el-card shadow="never">
      <div class="card-content">
        <el-form
            ref="form"
            :model="accountForm"
            label-width="160px"
            label-position="left"
            size="small"
            class="overflow-auto w-96"
            :rules="rules"
        >
          <el-form-item label="名称" prop="labelName">
            <el-input
                v-model="accountForm.labelName"
                placeholder="请输入名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="标签栏图标" prop="labelIcon">
            <el-upload
                class="h-48 w-48 border-gray-400 border-dashed border"
                action="#"
                :show-file-list="false"
                :http-request="labelIconUploader"
                accept="image/x-icon"
            >
              <img
                  class="p-1 object-contain h-48 w-48"
                  v-if="accountForm.labelIcon"
                  :src="accountForm.labelIcon"
                  alt=""
              />
              <i
                  v-else
                  class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"
              ></i>
            </el-upload>
            <div
                class="text-xs leading-6 font-sans antialiased text-gray-400 mt-1 select-none"
            >
              <p>建议上传：</p>
              <p>1. 图片大小：64PX * 64PX</p>
              <p>2. 图片格式：ICO</p>
            </div>
          </el-form-item>
          <el-form-item label="登录封面" prop="loginCoverImg">
            <el-upload
                class="h-48 w-48 border-gray-400 border-dashed border"
                action="#"
                :show-file-list="false"
                :http-request="loginCoverImgUploader"
                accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
            >
              <img
                  class="p-1 object-contain h-48 w-48"
                  v-if="accountForm.loginCoverImg"
                  :src="accountForm.loginCoverImg"
                  alt=""
              />
              <i
                  v-else
                  class="el-icon-plus text-3xl h-48 w-48 justify-content-center align-items-center flex"
              ></i>
            </el-upload>
            <div
                class="text-xs leading-6 font-sans antialiased text-gray-400 mt-1 select-none"
            >
              <p>建议上传：</p>
              <p>1. 图片大小：580PX * 460PX</p>
              <p>2. 图片格式：JPG、JPGE</p>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<style scoped lang="less" rel="stylesheet/less"></style>
<script type="text/ecmascript-6">
import oss from '../../utils/oss'

export default {
  data () {
    return {
      accountForm: {
        labelName: '',
        labelIcon: '',
        loginCoverImg: ''
      },
      rules: {}
    }
  },
  methods: {
    async labelIconUploader (item) {
      const { file } = item
      if (file.type !== 'image/x-icon') {
        this.$message.error('请选择ico文件')
        return false
      }
      this.accountForm.labelIcon = await oss.upload(file)
      this.$refs.form.validateField('labelIcon')
    },
    async loginCoverImgUploader (item) {
      const { file } = item
      this.accountForm.loginCoverImg = await oss.upload(file)
      this.$refs.form.validateField('loginCoverImg')
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.sysUser.accountUpdate(this.accountForm).then(res => {
            this.$message.success('保存成功,请刷新页面')
          })
        }
      })
    },
    getData () {
      this.$api.sysUser.accountSelect().then(res => {
        this.accountForm = res.data
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>
